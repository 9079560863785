import React from "react";
import Navbar from './Navbar';

export default function Experience(){

    return(
        <div className="proj">
            <Navbar />
        </div>
    );
}

